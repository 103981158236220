"use strict";

class SendDataChannel extends RTC {
    constructor(onMessage = () => null, onIce = () => null, onOpen = () => null) {
        super(onMessage, onIce, onOpen);
    }
    
    connect(iceServers) {
        this._connect(iceServers);
        this._createDataChannel();
    }

    _createDataChannel() {
        if (typeof this.peerConnection.createDataChannel !== "function") {
            Notify.error("Your browser does not support data channels")
        }

        this.dataChannel = this.peerConnection.createDataChannel('sendDataChannel');
        this.dataChannel.binaryType = 'arraybuffer';

        this.dataChannel.onmessage = this._onRecieveMessage.bind(this);
        this.dataChannel.onclose = this.closeConnections.bind(this);
        this.dataChannel.onopen = () => {
            console.log("Data channel open as sender");
            if (this.dataChannel.ordered == false) console.warn("unreliable data channel!");
            sendEvent('dataChannelOpened', { "orderedDataChannel": this.dataChannel.ordered, "sender": true });
            this.onOpen();
        };
        
        this.dataChannel.onerror = (err) => console.error("data channel error", err);
    }
}
